const legacySite = window.site.legacyPageBaseUrl;
const coreSite = window.site.corePageBaseUrl;
export const routes = {
    "artistRider_New": () => ({ relative: "/artistrider/new", absolute: legacySite + "/artistrider/new"}),
    "artistRider_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/artistrider/edit/{key}", key),
            absolute: replace(legacySite + "/artistrider/edit/{key}", key)
        };
    },
    "artistRider_List": () => ({ relative: "/artistrider", absolute: legacySite + "/artistrider"}),
    "company_New": () => ({ relative: "/company/new", absolute: legacySite + "/company/new"}),
    "company_NewModal": () => ({ relative: "/company/new/modal", absolute: legacySite + "/company/new/modal"}),
    "company_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/company/{key}/edit", key),
            absolute: replace(legacySite + "/company/{key}/edit", key)
        };
    },
    "company_EditModal": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/company/{key}/edit/modal", key),
            absolute: replace(legacySite + "/company/{key}/edit/modal", key)
        };
    },
    "company_Merge": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/company/{key}/merge", key),
            absolute: replace(legacySite + "/company/{key}/merge", key)
        };
    },
    "company_ChooseMergeTarget": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/company/{key}/choosetarget", key),
            absolute: replace(legacySite + "/company/{key}/choosetarget", key)
        };
    },
    "company_Map": () => ({ relative: "/company/map", absolute: legacySite + "/company/map"}),
    "company_VATNumber": () => ({ relative: "/company/vatnumber", absolute: legacySite + "/company/vatnumber"}),
    "mergefield_Company_List": () => ({ relative: "/company/mergefield", absolute: legacySite + "/company/mergefield"}),
    "contact_New": () => ({ relative: "/contact/new", absolute: legacySite + "/contact/new"}),
    "contact_NewModal": () => ({ relative: "/contact/new/modal", absolute: legacySite + "/contact/new/modal"}),
    "contact_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/contact/{key}/edit", key),
            absolute: replace(legacySite + "/contact/{key}/edit", key)
        };
    },
    "contact_EditModal": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/contact/{key}/edit/modal", key),
            absolute: replace(legacySite + "/contact/{key}/edit/modal", key)
        };
    },
    "contact_Merge": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/contact/{key}/merge", key),
            absolute: replace(legacySite + "/contact/{key}/merge", key)
        };
    },
    "contact_ChooseMergeTarget": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/contact/{key}/choosetarget", key),
            absolute: replace(legacySite + "/contact/{key}/choosetarget", key)
        };
    },
    "mergefield_Contact_List": () => ({ relative: "/contact/mergefield", absolute: legacySite + "/contact/mergefield"}),
    "production_New": () => ({ relative: "/production/new", absolute: legacySite + "/production/new"}),
    "production_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/production/{key}", key),
            absolute: replace(legacySite + "/production/{key}", key)
        };
    },
    "production_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/production/{key}/edit", key),
            absolute: replace(legacySite + "/production/{key}/edit", key)
        };
    },
    "production_Delete": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/production/{key}/delete", key),
            absolute: replace(legacySite + "/production/{key}/delete", key)
        };
    },
    "freeFieldCategory_Production_New": () => ({ relative: "/production/freefieldcategory/new", absolute: legacySite + "/production/freefieldcategory/new"}),
    "freeFieldCategory_Production_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/production/freefieldcategory/{key}/edit", key),
            absolute: replace(legacySite + "/production/freefieldcategory/{key}/edit", key)
        };
    },
    "freeFieldCategory_Production_List": () => ({ relative: "/production/freefieldcategory", absolute: legacySite + "/production/freefieldcategory"}),
    "freeFieldDefinition_Production_New": () => ({ relative: "/production/freefielddefinition/new", absolute: legacySite + "/production/freefielddefinition/new"}),
    "freeFieldDefinition_Production_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/production/freefielddefinition/{key}/edit", key),
            absolute: replace(legacySite + "/production/freefielddefinition/{key}/edit", key)
        };
    },
    "mergefield_Production_List": () => ({ relative: "/production/mergefield", absolute: legacySite + "/production/mergefield"}),
    "freeFieldProfile_Internal_New": () => ({ relative: "/freefieldprofile/internal/new", absolute: legacySite + "/freefieldprofile/internal/new"}),
    "freeFieldProfile_Internal_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/freefieldprofile/internal/{key}/edit", key),
            absolute: replace(legacySite + "/freefieldprofile/internal/{key}/edit", key)
        };
    },
    "freeFieldProfile_Internal_List": () => ({ relative: "/freefieldprofile/internal", absolute: legacySite + "/freefieldprofile/internal"}),
    "freeFieldProfile_External_New": () => ({ relative: "/freefieldprofile/external/new", absolute: legacySite + "/freefieldprofile/external/new"}),
    "freeFieldProfile_External_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/freefieldprofile/external/{key}/edit", key),
            absolute: replace(legacySite + "/freefieldprofile/external/{key}/edit", key)
        };
    },
    "freeFieldProfile_External_List": () => ({ relative: "/freefieldprofile/external", absolute: legacySite + "/freefieldprofile/external"}),
    "planning_Itinerary": () => ({ relative: "/planning/itinerary", absolute: legacySite + "/planning/itinerary"}),
    "show_FromWebform": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/show/{key}/fromwebform", key),
            absolute: replace(legacySite + "/show/{key}/fromwebform", key)
        };
    },
    "show_Totals": () => ({ relative: "/show/total", absolute: legacySite + "/show/total"}),
    "show_LockShows": () => ({ relative: "/show/lock", absolute: legacySite + "/show/lock"}),
    "freeFieldCategory_Show_New": () => ({ relative: "/show/freefieldcategory/new", absolute: legacySite + "/show/freefieldcategory/new"}),
    "freeFieldCategory_Show_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/show/freefieldcategory/{key}/edit", key),
            absolute: replace(legacySite + "/show/freefieldcategory/{key}/edit", key)
        };
    },
    "freeFieldCategory_Show_List": () => ({ relative: "/show/freefieldcategory", absolute: legacySite + "/show/freefieldcategory"}),
    "freeFieldDefinition_Show_New": () => ({ relative: "/show/freefielddefinition/new", absolute: legacySite + "/show/freefielddefinition/new"}),
    "freeFieldDefinition_Show_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/show/freefielddefinition/{key}/edit", key),
            absolute: replace(legacySite + "/show/freefielddefinition/{key}/edit", key)
        };
    },
    "mergefield_Show_List": () => ({ relative: "/show/mergefield", absolute: legacySite + "/show/mergefield"}),
    "show_NewInvoice": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/show/{key}/invoice/new", key),
            absolute: replace(legacySite + "/show/{key}/invoice/new", key)
        };
    },
    "show_NewPurchaseAgreement": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/show/{key}/purchaseagreement/new", key),
            absolute: replace(legacySite + "/show/{key}/purchaseagreement/new", key)
        };
    },
    "show_NewContract": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/show/{key}/contract/new", key),
            absolute: replace(legacySite + "/show/{key}/contract/new", key)
        };
    },
    "show_NewQuote": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/show/{key}/quote/new", key),
            absolute: replace(legacySite + "/show/{key}/quote/new", key)
        };
    },
    "train_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/train/{key}", key),
            absolute: replace(legacySite + "/train/{key}", key)
        };
    },
    "groundTransport_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/groundtransport/{key}", key),
            absolute: replace(legacySite + "/groundtransport/{key}", key)
        };
    },
    "user_ListAdminOne": () => ({ relative: "/adminone/user", absolute: legacySite + "/adminone/user"}),
    "user_DetailAdminOne": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/adminone/user/{key}", key),
            absolute: replace(legacySite + "/adminone/user/{key}", key)
        };
    },
    "userAction_List": () => ({ relative: "/useraction", absolute: legacySite + "/useraction"}),
    "file_Overview": () => ({ relative: "/file", absolute: legacySite + "/file"}),
    "gdpr_Agreement": () => ({ relative: "/gdpr/agreement", absolute: legacySite + "/gdpr/agreement"}),
    "taskManager_List": () => ({ relative: "/taskmanager", absolute: legacySite + "/taskmanager"}),
    "invoice_New": () => ({ relative: "/invoice/new", absolute: legacySite + "/invoice/new"}),
    "invoice_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/invoice/{key}", key),
            absolute: replace(legacySite + "/invoice/{key}", key)
        };
    },
    "invoice_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/invoice/{key}/edit", key),
            absolute: replace(legacySite + "/invoice/{key}/edit", key)
        };
    },
    "invoice_Copy": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/invoice/{key}/copy", key),
            absolute: replace(legacySite + "/invoice/{key}/copy", key)
        };
    },
    "invoice_MakeCredit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/invoice/{key}/makecredit", key),
            absolute: replace(legacySite + "/invoice/{key}/makecredit", key)
        };
    },
    "invoice_Statistics": () => ({ relative: "/invoice/stats", absolute: legacySite + "/invoice/stats"}),
    "invoice_Totals": () => ({ relative: "/invoice/total", absolute: legacySite + "/invoice/total"}),
    "mergefield_Invoice_List": () => ({ relative: "/invoice/mergefield", absolute: legacySite + "/invoice/mergefield"}),
    "invoice_SalesDataExport": () => ({ relative: "/invoice/dataexport", absolute: legacySite + "/invoice/dataexport"}),
    "purchaseAgreement_New": () => ({ relative: "/purchaseagreement/new", absolute: legacySite + "/purchaseagreement/new"}),
    "purchaseAgreement_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/purchaseagreement/{key}", key),
            absolute: replace(legacySite + "/purchaseagreement/{key}", key)
        };
    },
    "purchaseAgreement_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/purchaseagreement/{key}/edit", key),
            absolute: replace(legacySite + "/purchaseagreement/{key}/edit", key)
        };
    },
    "purchaseAgreement_Copy": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/purchaseagreement/{key}/copy", key),
            absolute: replace(legacySite + "/purchaseagreement/{key}/copy", key)
        };
    },
    "purchaseAgreement_Statistics": () => ({ relative: "/purchaseagreement/stats", absolute: legacySite + "/purchaseagreement/stats"}),
    "purchaseAgreement_Totals": () => ({ relative: "/purchaseagreement/total", absolute: legacySite + "/purchaseagreement/total"}),
    "mergefield_PurgeAgreement_List": () => ({ relative: "/purchaseagreement/mergefield", absolute: legacySite + "/purchaseagreement/mergefield"}),
    "contract_New": () => ({ relative: "/contract/new", absolute: legacySite + "/contract/new"}),
    "contract_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/contract/{key}", key),
            absolute: replace(legacySite + "/contract/{key}", key)
        };
    },
    "contract_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/contract/{key}/edit", key),
            absolute: replace(legacySite + "/contract/{key}/edit", key)
        };
    },
    "contract_Copy": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/contract/{key}/copy", key),
            absolute: replace(legacySite + "/contract/{key}/copy", key)
        };
    },
    "contract_Statistics": () => ({ relative: "/contract/stats", absolute: legacySite + "/contract/stats"}),
    "contract_Totals": () => ({ relative: "/contract/total", absolute: legacySite + "/contract/total"}),
    "mergefield_Contract_List": () => ({ relative: "/contract/mergefield", absolute: legacySite + "/contract/mergefield"}),
    "quote_New": () => ({ relative: "/quote/new", absolute: legacySite + "/quote/new"}),
    "quote_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/quote/{key}", key),
            absolute: replace(legacySite + "/quote/{key}", key)
        };
    },
    "quote_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/quote/{key}/edit", key),
            absolute: replace(legacySite + "/quote/{key}/edit", key)
        };
    },
    "quote_Copy": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/quote/{key}/copy", key),
            absolute: replace(legacySite + "/quote/{key}/copy", key)
        };
    },
    "quote_Statistics": () => ({ relative: "/quote/stats", absolute: legacySite + "/quote/stats"}),
    "quote_Totals": () => ({ relative: "/quote/total", absolute: legacySite + "/quote/total"}),
    "mergefield_Quote_List": () => ({ relative: "/quote/mergefield", absolute: legacySite + "/quote/mergefield"}),
    "iCalendarTemplate_New": () => ({ relative: "/template/icalendar/new", absolute: legacySite + "/template/icalendar/new"}),
    "iCalendarTemplate_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/template/icalendar/edit/{key}", key),
            absolute: replace(legacySite + "/template/icalendar/edit/{key}", key)
        };
    },
    "iCalendarTemplate_List": () => ({ relative: "/template/icalendar", absolute: legacySite + "/template/icalendar"}),
    "wordTemplate_Company_New": () => ({ relative: "/company/wordtemplate/new", absolute: legacySite + "/company/wordtemplate/new"}),
    "wordTemplate_Contact_New": () => ({ relative: "/contact/wordtemplate/new", absolute: legacySite + "/contact/wordtemplate/new"}),
    "wordTemplate_Show_New": () => ({ relative: "/show/wordtemplate/new", absolute: legacySite + "/show/wordtemplate/new"}),
    "wordTemplate_PurchaseAgreement_New": () => ({ relative: "/purchaseagreement/wordtemplate/new", absolute: legacySite + "/purchaseagreement/wordtemplate/new"}),
    "wordTemplate_Contract_New": () => ({ relative: "/contract/wordtemplate/new", absolute: legacySite + "/contract/wordtemplate/new"}),
    "wordTemplate_Invoice_New": () => ({ relative: "/invoice/wordtemplate/new", absolute: legacySite + "/invoice/wordtemplate/new"}),
    "wordTemplate_Quote_New": () => ({ relative: "/quote/wordtemplate/new", absolute: legacySite + "/quote/wordtemplate/new"}),
    "wordTemplate_Company_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/company/wordtemplate/{key}/edit", key),
            absolute: replace(legacySite + "/company/wordtemplate/{key}/edit", key)
        };
    },
    "wordTemplate_Contact_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/contact/wordtemplate/{key}/edit", key),
            absolute: replace(legacySite + "/contact/wordtemplate/{key}/edit", key)
        };
    },
    "wordTemplate_Show_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/show/wordtemplate/{key}/edit", key),
            absolute: replace(legacySite + "/show/wordtemplate/{key}/edit", key)
        };
    },
    "wordTemplate_PurchaseAgreement_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/purchaseagreement/wordtemplate/{key}/edit", key),
            absolute: replace(legacySite + "/purchaseagreement/wordtemplate/{key}/edit", key)
        };
    },
    "wordTemplate_Contract_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/contract/wordtemplate/{key}/edit", key),
            absolute: replace(legacySite + "/contract/wordtemplate/{key}/edit", key)
        };
    },
    "wordTemplate_Invoice_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/invoice/wordtemplate/{key}/edit", key),
            absolute: replace(legacySite + "/invoice/wordtemplate/{key}/edit", key)
        };
    },
    "wordTemplate_Quote_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/quote/wordtemplate/{key}/edit", key),
            absolute: replace(legacySite + "/quote/wordtemplate/{key}/edit", key)
        };
    },
    "wordTemplateForListView_Company_List": () => ({ relative: "/company/wordlistexporttemplate", absolute: legacySite + "/company/wordlistexporttemplate"}),
    "wordTemplateForListView_Contact_List": () => ({ relative: "/contact/wordlistexporttemplate", absolute: legacySite + "/contact/wordlistexporttemplate"}),
    "wordTemplateForListView_Show_List": () => ({ relative: "/show/wordlistexporttemplate", absolute: legacySite + "/show/wordlistexporttemplate"}),
    "wordTemplateForListView_PurchaseAgreement_List": () => ({ relative: "/purchaseagreement/wordlistexporttemplate", absolute: legacySite + "/purchaseagreement/wordlistexporttemplate"}),
    "wordTemplateForListView_Contract_List": () => ({ relative: "/contract/wordlistexporttemplate", absolute: legacySite + "/contract/wordlistexporttemplate"}),
    "wordTemplateForListView_Invoice_List": () => ({ relative: "/invoice/wordlistexporttemplate", absolute: legacySite + "/invoice/wordlistexporttemplate"}),
    "wordTemplateForListView_Quote_List": () => ({ relative: "/quote/wordlistexporttemplate", absolute: legacySite + "/quote/wordlistexporttemplate"}),
    "wordTemplateForListView_Company_New": () => ({ relative: "/company/wordlistexporttemplate/new", absolute: legacySite + "/company/wordlistexporttemplate/new"}),
    "wordTemplateForListView_Contact_New": () => ({ relative: "/contact/wordlistexporttemplate/new", absolute: legacySite + "/contact/wordlistexporttemplate/new"}),
    "wordTemplateForListView_Show_New": () => ({ relative: "/show/wordlistexporttemplate/new", absolute: legacySite + "/show/wordlistexporttemplate/new"}),
    "wordTemplateForListView_PurchaseAgreement_New": () => ({ relative: "/purchaseagreement/wordlistexporttemplate/new", absolute: legacySite + "/purchaseagreement/wordlistexporttemplate/new"}),
    "wordTemplateForListView_Contract_New": () => ({ relative: "/contract/wordlistexporttemplate/new", absolute: legacySite + "/contract/wordlistexporttemplate/new"}),
    "wordTemplateForListView_Invoice_New": () => ({ relative: "/invoice/wordlistexporttemplate/new", absolute: legacySite + "/invoice/wordlistexporttemplate/new"}),
    "wordTemplateForListView_Quote_New": () => ({ relative: "/quote/wordlistexporttemplate/new", absolute: legacySite + "/quote/wordlistexporttemplate/new"}),
    "wordTemplateForListView_Company_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/company/wordlistexporttemplate/{key}/edit", key),
            absolute: replace(legacySite + "/company/wordlistexporttemplate/{key}/edit", key)
        };
    },
    "wordTemplateForListView_Contact_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/contact/wordlistexporttemplate/{key}/edit", key),
            absolute: replace(legacySite + "/contact/wordlistexporttemplate/{key}/edit", key)
        };
    },
    "wordTemplateForListView_Show_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/show/wordlistexporttemplate/{key}/edit", key),
            absolute: replace(legacySite + "/show/wordlistexporttemplate/{key}/edit", key)
        };
    },
    "wordTemplateForListView_PurchaseAgreement_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/purchaseagreement/wordlistexporttemplate/{key}/edit", key),
            absolute: replace(legacySite + "/purchaseagreement/wordlistexporttemplate/{key}/edit", key)
        };
    },
    "wordTemplateForListView_Contract_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/contract/wordlistexporttemplate/{key}/edit", key),
            absolute: replace(legacySite + "/contract/wordlistexporttemplate/{key}/edit", key)
        };
    },
    "wordTemplateForListView_Invoice_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/invoice/wordlistexporttemplate/{key}/edit", key),
            absolute: replace(legacySite + "/invoice/wordlistexporttemplate/{key}/edit", key)
        };
    },
    "wordTemplateForListView_Quote_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/quote/wordlistexporttemplate/{key}/edit", key),
            absolute: replace(legacySite + "/quote/wordlistexporttemplate/{key}/edit", key)
        };
    },
    "excelTemplate_Company_List": () => ({ relative: "/company/exceltemplate", absolute: legacySite + "/company/exceltemplate"}),
    "excelTemplate_Contact_List": () => ({ relative: "/contact/exceltemplate", absolute: legacySite + "/contact/exceltemplate"}),
    "excelTemplate_Production_List": () => ({ relative: "/production/exceltemplate", absolute: legacySite + "/production/exceltemplate"}),
    "excelTemplate_Show_List": () => ({ relative: "/show/exceltemplate", absolute: legacySite + "/show/exceltemplate"}),
    "excelTemplate_PurchaseAgreement_List": () => ({ relative: "/purchaseagreement/exceltemplate", absolute: legacySite + "/purchaseagreement/exceltemplate"}),
    "excelTemplate_Contract_List": () => ({ relative: "/contract/exceltemplate", absolute: legacySite + "/contract/exceltemplate"}),
    "excelTemplate_Invoice_List": () => ({ relative: "/invoice/exceltemplate", absolute: legacySite + "/invoice/exceltemplate"}),
    "excelTemplate_Quote_List": () => ({ relative: "/quote/exceltemplate", absolute: legacySite + "/quote/exceltemplate"}),
    "excelTemplate_Company_New": () => ({ relative: "/company/exceltemplate/new", absolute: legacySite + "/company/exceltemplate/new"}),
    "excelTemplate_Contact_New": () => ({ relative: "/contact/exceltemplate/new", absolute: legacySite + "/contact/exceltemplate/new"}),
    "excelTemplate_Production_New": () => ({ relative: "/production/exceltemplate/new", absolute: legacySite + "/production/exceltemplate/new"}),
    "excelTemplate_Show_New": () => ({ relative: "/show/exceltemplate/new", absolute: legacySite + "/show/exceltemplate/new"}),
    "excelTemplate_PurchaseAgreement_New": () => ({ relative: "/purchaseagreement/exceltemplate/new", absolute: legacySite + "/purchaseagreement/exceltemplate/new"}),
    "excelTemplate_Contract_New": () => ({ relative: "/contract/exceltemplate/new", absolute: legacySite + "/contract/exceltemplate/new"}),
    "excelTemplate_Invoice_New": () => ({ relative: "/invoice/exceltemplate/new", absolute: legacySite + "/invoice/exceltemplate/new"}),
    "excelTemplate_Quote_New": () => ({ relative: "/quote/exceltemplate/new", absolute: legacySite + "/quote/exceltemplate/new"}),
    "excelTemplate_Company_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/company/exceltemplate/{key}/edit", key),
            absolute: replace(legacySite + "/company/exceltemplate/{key}/edit", key)
        };
    },
    "excelTemplate_Contact_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/contact/exceltemplate/{key}/edit", key),
            absolute: replace(legacySite + "/contact/exceltemplate/{key}/edit", key)
        };
    },
    "excelTemplate_Production_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/production/exceltemplate/{key}/edit", key),
            absolute: replace(legacySite + "/production/exceltemplate/{key}/edit", key)
        };
    },
    "excelTemplate_Show_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/show/exceltemplate/{key}/edit", key),
            absolute: replace(legacySite + "/show/exceltemplate/{key}/edit", key)
        };
    },
    "excelTemplate_PurchaseAgreement_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/purchaseagreement/exceltemplate/{key}/edit", key),
            absolute: replace(legacySite + "/purchaseagreement/exceltemplate/{key}/edit", key)
        };
    },
    "excelTemplate_Contract_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/contract/exceltemplate/{key}/edit", key),
            absolute: replace(legacySite + "/contract/exceltemplate/{key}/edit", key)
        };
    },
    "excelTemplate_Invoice_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/invoice/exceltemplate/{key}/edit", key),
            absolute: replace(legacySite + "/invoice/exceltemplate/{key}/edit", key)
        };
    },
    "excelTemplate_Quote_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/quote/exceltemplate/{key}/edit", key),
            absolute: replace(legacySite + "/quote/exceltemplate/{key}/edit", key)
        };
    },
    "emailTemplate_Company_List": () => ({ relative: "/company/emailtemplate", absolute: legacySite + "/company/emailtemplate"}),
    "emailTemplate_Contact_List": () => ({ relative: "/contact/emailtemplate", absolute: legacySite + "/contact/emailtemplate"}),
    "emailTemplate_Show_List": () => ({ relative: "/show/emailtemplate", absolute: legacySite + "/show/emailtemplate"}),
    "emailTemplate_PurchaseAgreement_List": () => ({ relative: "/purchaseagreement/emailtemplate", absolute: legacySite + "/purchaseagreement/emailtemplate"}),
    "emailTemplate_Contract_List": () => ({ relative: "/contract/emailtemplate", absolute: legacySite + "/contract/emailtemplate"}),
    "emailTemplate_Invoice_List": () => ({ relative: "/invoice/emailtemplate", absolute: legacySite + "/invoice/emailtemplate"}),
    "emailTemplate_Quote_List": () => ({ relative: "/quote/emailtemplate", absolute: legacySite + "/quote/emailtemplate"}),
    "emailTemplate_Company_New": () => ({ relative: "/company/emailtemplate/new", absolute: legacySite + "/company/emailtemplate/new"}),
    "emailTemplate_Contact_New": () => ({ relative: "/contact/emailtemplate/new", absolute: legacySite + "/contact/emailtemplate/new"}),
    "emailTemplate_Show_New": () => ({ relative: "/show/emailtemplate/new", absolute: legacySite + "/show/emailtemplate/new"}),
    "emailTemplate_PurchaseAgreement_New": () => ({ relative: "/purchaseagreement/emailtemplate/new", absolute: legacySite + "/purchaseagreement/emailtemplate/new"}),
    "emailTemplate_Contract_New": () => ({ relative: "/contract/emailtemplate/new", absolute: legacySite + "/contract/emailtemplate/new"}),
    "emailTemplate_Invoice_New": () => ({ relative: "/invoice/emailtemplate/new", absolute: legacySite + "/invoice/emailtemplate/new"}),
    "emailTemplate_Quote_New": () => ({ relative: "/quote/emailtemplate/new", absolute: legacySite + "/quote/emailtemplate/new"}),
    "emailTemplate_Company_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/company/emailtemplate/{key}/edit", key),
            absolute: replace(legacySite + "/company/emailtemplate/{key}/edit", key)
        };
    },
    "emailTemplate_Contact_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/contact/emailtemplate/{key}/edit", key),
            absolute: replace(legacySite + "/contact/emailtemplate/{key}/edit", key)
        };
    },
    "emailTemplate_Show_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/show/emailtemplate/{key}/edit", key),
            absolute: replace(legacySite + "/show/emailtemplate/{key}/edit", key)
        };
    },
    "emailTemplate_PurchaseAgreement_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/purchaseagreement/emailtemplate/{key}/edit", key),
            absolute: replace(legacySite + "/purchaseagreement/emailtemplate/{key}/edit", key)
        };
    },
    "emailTemplate_Contract_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/contract/emailtemplate/{key}/edit", key),
            absolute: replace(legacySite + "/contract/emailtemplate/{key}/edit", key)
        };
    },
    "emailTemplate_Invoice_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/invoice/emailtemplate/{key}/edit", key),
            absolute: replace(legacySite + "/invoice/emailtemplate/{key}/edit", key)
        };
    },
    "emailTemplate_Quote_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/quote/emailtemplate/{key}/edit", key),
            absolute: replace(legacySite + "/quote/emailtemplate/{key}/edit", key)
        };
    },
    "internalUser_Modify": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/user/internal/{key}/edit", key),
            absolute: replace(legacySite + "/user/internal/{key}/edit", key)
        };
    },
    "internalUser_List": () => ({ relative: "/user/internal", absolute: legacySite + "/user/internal"}),
    "externalUser_Modify": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/user/external/{key}/edit", key),
            absolute: replace(legacySite + "/user/external/{key}/edit", key)
        };
    },
    "externalUser_List": () => ({ relative: "/user/external", absolute: legacySite + "/user/external"}),
    "systemSetting_ICalendarFeed_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/icalendarfeed/{key}", key),
            absolute: replace(legacySite + "/icalendarfeed/{key}", key)
        };
    },
    "productGroup_New": () => ({ relative: "/productgroup/new", absolute: legacySite + "/productgroup/new"}),
    "productGroup_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/productgroup/{key}/edit", key),
            absolute: replace(legacySite + "/productgroup/{key}/edit", key)
        };
    },
    "productTemplate_New": () => ({ relative: "/producttemplate/new", absolute: legacySite + "/producttemplate/new"}),
    "productTemplate_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/producttemplate/{key}/edit", key),
            absolute: replace(legacySite + "/producttemplate/{key}/edit", key)
        };
    },
    "productTemplate_List": () => ({ relative: "/producttemplate", absolute: legacySite + "/producttemplate"}),
    "client_NewAdminOne": () => ({ relative: "/adminone/client/new", absolute: legacySite + "/adminone/client/new"}),
    "client_EditAdminOne": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/adminone/client/{key}/edit", key),
            absolute: replace(legacySite + "/adminone/client/{key}/edit", key)
        };
    },
    "client_DetailAdminOne": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/adminone/client/{key}", key),
            absolute: replace(legacySite + "/adminone/client/{key}", key)
        };
    },
    "client_ListAdminOne": () => ({ relative: "/adminone/client", absolute: legacySite + "/adminone/client"}),
    "bookkeeping_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/bookkeeping/{key}/edit", key),
            absolute: replace(legacySite + "/bookkeeping/{key}/edit", key)
        };
    },
    "bookkeeping_List": () => ({ relative: "/bookkeeping", absolute: legacySite + "/bookkeeping"}),
    "country_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/country/{key}", key),
            absolute: replace(legacySite + "/country/{key}", key)
        };
    },
    "country_List": () => ({ relative: "/country", absolute: legacySite + "/country"}),
    "iCalendarFeed_Help": (secret: string) => {
        const replace = (input: string, secret: string) => input.replace("{secret}", secret.toString());
        return {
            relative: replace("/ical/{secret}/help", secret),
            absolute: replace(legacySite + "/ical/{secret}/help", secret)
        };
    },
    "webform_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/webform/{key}/edit", key),
            absolute: replace(legacySite + "/webform/{key}/edit", key)
        };
    },
    "webform_List": () => ({ relative: "/webform", absolute: legacySite + "/webform"}),
    "webformToFunctiongroup_EditArtist": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/webform/{key}/artist", key),
            absolute: replace(legacySite + "/webform/{key}/artist", key)
        };
    },
    "webformToFunctiongroup_EditVenue": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/webform/{key}/venue", key),
            absolute: replace(legacySite + "/webform/{key}/venue", key)
        };
    },
    "webformToFunctiongroup_EditPromoter": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/webform/{key}/promoter", key),
            absolute: replace(legacySite + "/webform/{key}/promoter", key)
        };
    },
    "embedShowsWizard": () => ({ relative: "/wizard/embedshow", absolute: legacySite + "/wizard/embedshow"}),
    "externalEvent_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/externalevent/{key}", key),
            absolute: replace(legacySite + "/externalevent/{key}", key)
        };
    },
    "externalCalendar_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/externalcalendar/{key}", key),
            absolute: replace(legacySite + "/externalcalendar/{key}", key)
        };
    },
    "externalCalendar_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/externalcalendar/{key}/edit", key),
            absolute: replace(legacySite + "/externalcalendar/{key}/edit", key)
        };
    },
    "externalCalendar_New": () => ({ relative: "/externalcalendar/new", absolute: legacySite + "/externalcalendar/new"}),
    "airport_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/airport/{key}", key),
            absolute: replace(legacySite + "/airport/{key}", key)
        };
    },
    "exchangeRate_List": () => ({ relative: "/exchangerate", absolute: legacySite + "/exchangerate"}),
    "advancingTemplate_List": () => ({ relative: "/advancing/template", absolute: legacySite + "/advancing/template"}),
    "advancingTemplate_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/advancing/template/{key}", key),
            absolute: replace(legacySite + "/advancing/template/{key}", key)
        };
    },
    "advancingTemplate_New": () => ({ relative: "/advancing/template/new", absolute: legacySite + "/advancing/template/new"}),
    "advancingApprove_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/advancing/approve/{key}", key),
            absolute: replace(legacySite + "/advancing/approve/{key}", key)
        };
    },
    "notification_List": () => ({ relative: "/notification", absolute: legacySite + "/notification"}),
    "personal_ICalendarFeed_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/personalsettings/icalendarfeed/{key}", key),
            absolute: replace(legacySite + "/personalsettings/icalendarfeed/{key}", key)
        };
    },
    "account_ReprimandedForCheating": () => ({ relative: "/account/multiuse/detected", absolute: coreSite + "/account/multiuse/detected"}),
    "account_ResetMyPassword": () => ({ relative: "/account/resetmypassword", absolute: coreSite + "/account/resetmypassword"}),
    "account_SignIn": () => ({ relative: "/account/signin", absolute: coreSite + "/account/signin"}),
    "advancing_InvitationLanding": (secret: string) => {
        const replace = (input: string, secret: string) => input.replace("{secret}", secret.toString());
        return {
            relative: replace("/advancing/invitation/{secret}", secret),
            absolute: replace(coreSite + "/advancing/invitation/{secret}", secret)
        };
    },
    "advancing_Provide": (secret: string) => {
        const replace = (input: string, secret: string) => input.replace("{secret}", secret.toString());
        return {
            relative: replace("/advancing/invitation/{secret}/provide", secret),
            absolute: replace(coreSite + "/advancing/invitation/{secret}/provide", secret)
        };
    },
    "airport_List": () => ({ relative: "/airport", absolute: coreSite + "/airport"}),
    "article_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/article/{key}/edit", key),
            absolute: replace(coreSite + "/article/{key}/edit", key)
        };
    },
    "article_List": (section: string) => {
        const replace = (input: string, section: string) => input.replace("{section}", section.toString());
        return {
            relative: replace("/article/{section}", section),
            absolute: replace(coreSite + "/article/{section}", section)
        };
    },
    "articleCategory_List": (section: string) => {
        const replace = (input: string, section: string) => input.replace("{section}", section.toString());
        return {
            relative: replace("/articlecategory/{section}", section),
            absolute: replace(coreSite + "/articlecategory/{section}", section)
        };
    },
    "bankAccount_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/bankaccount/{key}/edit", key),
            absolute: replace(coreSite + "/bankaccount/{key}/edit", key)
        };
    },
    "bankAccount_List": () => ({ relative: "/bankaccount", absolute: coreSite + "/bankaccount"}),
    "bankAccount_New": () => ({ relative: "/bankaccount/new", absolute: coreSite + "/bankaccount/new"}),
    "bookkeeping_New": () => ({ relative: "/bookkeeping/new", absolute: coreSite + "/bookkeeping/new"}),
    "brand_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/brand/{key}/edit", key),
            absolute: replace(coreSite + "/brand/{key}/edit", key)
        };
    },
    "brand_List": () => ({ relative: "/brand", absolute: coreSite + "/brand"}),
    "businessCentralProject_List": () => ({ relative: "/businesscentralproject", absolute: coreSite + "/businesscentralproject"}),
    "checklistPresetItem_List": () => ({ relative: "/checklistpreset", absolute: coreSite + "/checklistpreset"}),
    "client_AccessStatus": () => ({ relative: "/client/access/status", absolute: coreSite + "/client/access/status"}),
    "clientOnboarding_Landing": (secret: string) => {
        const replace = (input: string, secret: string) => input.replace("{secret}", secret.toString());
        return {
            relative: replace("/clientonboarding/{secret}", secret),
            absolute: replace(coreSite + "/clientonboarding/{secret}", secret)
        };
    },
    "company_Artist_List": () => ({ relative: "/artist", absolute: coreSite + "/artist"}),
    "company_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/company/{key}", key),
            absolute: replace(coreSite + "/company/{key}", key)
        };
    },
    "company_List": () => ({ relative: "/company", absolute: coreSite + "/company"}),
    "company_Promoter_List": () => ({ relative: "/promoter", absolute: coreSite + "/promoter"}),
    "company_Venue_List": () => ({ relative: "/venue", absolute: coreSite + "/venue"}),
    "contact_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/contact/{key}", key),
            absolute: replace(coreSite + "/contact/{key}", key)
        };
    },
    "contact_List": () => ({ relative: "/contact", absolute: coreSite + "/contact"}),
    "contract_ESign": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/contract/{key}/esign", key),
            absolute: replace(coreSite + "/contract/{key}/esign", key)
        };
    },
    "contract_List": () => ({ relative: "/contract", absolute: coreSite + "/contract"}),
    "costCenter_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/costcenter/{key}/edit", key),
            absolute: replace(coreSite + "/costcenter/{key}/edit", key)
        };
    },
    "costCenter_List": () => ({ relative: "/costcenter", absolute: coreSite + "/costcenter"}),
    "culture_List": () => ({ relative: "/culture", absolute: coreSite + "/culture"}),
    "dashboard": () => ({ relative: "/", absolute: coreSite + "/"}),
    "diagnostic_Page_Health": () => ({ relative: "/diagnostic/5915ab75-f6a9-4514-abce-b01b476c9944/health", absolute: coreSite + "/diagnostic/5915ab75-f6a9-4514-abce-b01b476c9944/health"}),
    "emailMessage_PublicDownload": (secret: string) => {
        const replace = (input: string, secret: string) => input.replace("{secret}", secret.toString());
        return {
            relative: replace("/download/{secret}", secret),
            absolute: replace(coreSite + "/download/{secret}", secret)
        };
    },
    "eSign_ForMeList": () => ({ relative: "/esign/forme", absolute: coreSite + "/esign/forme"}),
    "eSign_Landing": (secret: string) => {
        const replace = (input: string, secret: string) => input.replace("{secret}", secret.toString());
        return {
            relative: replace("/esign/invitation/{secret}", secret),
            absolute: replace(coreSite + "/esign/invitation/{secret}", secret)
        };
    },
    "externalCalendar_List": () => ({ relative: "/externalcalendar", absolute: coreSite + "/externalcalendar"}),
    "externalIdentity_ChooseUser": () => ({ relative: "/externalidentity/chooseuser", absolute: coreSite + "/externalidentity/chooseuser"}),
    "externalUser_Invite": () => ({ relative: "/user/external/invite", absolute: coreSite + "/user/external/invite"}),
    "flight_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/flight/{key}", key),
            absolute: replace(coreSite + "/flight/{key}", key)
        };
    },
    "flight_List": () => ({ relative: "/flight", absolute: coreSite + "/flight"}),
    "groundTransport_List": () => ({ relative: "/groundtransport", absolute: coreSite + "/groundtransport"}),
    "internalUser_Invite": () => ({ relative: "/user/internal/invite", absolute: coreSite + "/user/internal/invite"}),
    "invoice_List": () => ({ relative: "/invoice", absolute: coreSite + "/invoice"}),
    "messageTemplate_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/template/message/{key}/edit", key),
            absolute: replace(coreSite + "/template/message/{key}/edit", key)
        };
    },
    "messageTemplate_List": () => ({ relative: "/template/message", absolute: coreSite + "/template/message"}),
    "onlineInvoice_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/onlineinvoice/{key}/edit", key),
            absolute: replace(coreSite + "/onlineinvoice/{key}/edit", key)
        };
    },
    "onlineInvoice_Landing": (secret: string) => {
        const replace = (input: string, secret: string) => input.replace("{secret}", secret.toString());
        return {
            relative: replace("/onlineinvoice/invitation/{secret}", secret),
            absolute: replace(coreSite + "/onlineinvoice/invitation/{secret}", secret)
        };
    },
    "payment_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/payment/{key}", key),
            absolute: replace(coreSite + "/payment/{key}", key)
        };
    },
    "payment_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/payment/{key}/edit", key),
            absolute: replace(coreSite + "/payment/{key}/edit", key)
        };
    },
    "payment_List": () => ({ relative: "/payment", absolute: coreSite + "/payment"}),
    "personal_ChangeName": () => ({ relative: "/personalsettings/change-name", absolute: coreSite + "/personalsettings/change-name"}),
    "personal_ChangePassword": () => ({ relative: "/personalsettings/change-password", absolute: coreSite + "/personalsettings/change-password"}),
    "personal_ChangeSecretQuestion": () => ({ relative: "/personalsettings/change-secret-question", absolute: coreSite + "/personalsettings/change-secret-question"}),
    "personal_ConnectedAccount_List": () => ({ relative: "/personalsettings/connected-accounts", absolute: coreSite + "/personalsettings/connected-accounts"}),
    "personal_EmailSettings": () => ({ relative: "/personalsettings/email-settings", absolute: coreSite + "/personalsettings/email-settings"}),
    "personal_FavoriteSubject_List": () => ({ relative: "/personalsettings/favorite-subjects", absolute: coreSite + "/personalsettings/favorite-subjects"}),
    "personal_ICalendarFeed_List": () => ({ relative: "/personalsettings/icalendar-feeds", absolute: coreSite + "/personalsettings/icalendar-feeds"}),
    "personal_SettingMenu": () => ({ relative: "/personalsettings", absolute: coreSite + "/personalsettings"}),
    "personal_SignatureSettings": () => ({ relative: "/personalsettings/signature-settings", absolute: coreSite + "/personalsettings/signature-settings"}),
    "planning_Availability": () => ({ relative: "/planning/availability", absolute: coreSite + "/planning/availability"}),
    "planning_Month": () => ({ relative: "/planning/month", absolute: coreSite + "/planning/month"}),
    "planning_Quarter": () => ({ relative: "/planning/quarter", absolute: coreSite + "/planning/quarter"}),
    "planning_Week": () => ({ relative: "/planning/week", absolute: coreSite + "/planning/week"}),
    "preference_Defaults": () => ({ relative: "/personalsettings/defaults", absolute: coreSite + "/personalsettings/defaults"}),
    "preference_FeatureConfig": () => ({ relative: "/personalsettings/feature-configure", absolute: coreSite + "/personalsettings/feature-configure"}),
    "preference_NotificationSettings": () => ({ relative: "/personalsettings/notification-settings", absolute: coreSite + "/personalsettings/notification-settings"}),
    "preference_RegionalSettings": () => ({ relative: "/personalsettings/language-and-region", absolute: coreSite + "/personalsettings/language-and-region"}),
    "preference_Styling": () => ({ relative: "/personalsettings/style", absolute: coreSite + "/personalsettings/style"}),
    "productGroup_List": () => ({ relative: "/productgroup", absolute: coreSite + "/productgroup"}),
    "production_List": () => ({ relative: "/production", absolute: coreSite + "/production"}),
    "purchaseAgreement_ESign": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/purchaseagreement/{key}/esign", key),
            absolute: replace(coreSite + "/purchaseagreement/{key}/esign", key)
        };
    },
    "purchaseAgreement_List": () => ({ relative: "/purchaseagreement", absolute: coreSite + "/purchaseagreement"}),
    "quote_ESign": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/quote/{key}/esign", key),
            absolute: replace(coreSite + "/quote/{key}/esign", key)
        };
    },
    "quote_List": () => ({ relative: "/quote", absolute: coreSite + "/quote"}),
    "show_Copy": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/show/{key}/copy", key),
            absolute: replace(coreSite + "/show/{key}/copy", key)
        };
    },
    "show_Detail": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/show/{key}", key),
            absolute: replace(coreSite + "/show/{key}", key)
        };
    },
    "show_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/show/{key}/edit", key),
            absolute: replace(coreSite + "/show/{key}/edit", key)
        };
    },
    "show_List": () => ({ relative: "/show", absolute: coreSite + "/show"}),
    "show_New": () => ({ relative: "/show/new", absolute: coreSite + "/show/new"}),
    "stateAlias_Unmapped": () => ({ relative: "/statealias", absolute: coreSite + "/statealias"}),
    "status_Detail": (mainarea: server.dto.MainAreas, key: number) => {
        const replace = (input: string, mainarea: server.dto.MainAreas, key: number) => input.replace("{mainarea}", mainarea.toString()).replace("{key}", key.toString());
        return {
            relative: replace("/{mainarea}/status/{key}", mainarea, key),
            absolute: replace(coreSite + "/{mainarea}/status/{key}", mainarea, key)
        };
    },
    "status_Edit": (mainarea: server.dto.MainAreas, key: number) => {
        const replace = (input: string, mainarea: server.dto.MainAreas, key: number) => input.replace("{mainarea}", mainarea.toString()).replace("{key}", key.toString());
        return {
            relative: replace("/{mainarea}/status/{key}/edit", mainarea, key),
            absolute: replace(coreSite + "/{mainarea}/status/{key}/edit", mainarea, key)
        };
    },
    "status_List": (mainarea: server.dto.MainAreas) => {
        const replace = (input: string, mainarea: server.dto.MainAreas) => input.replace("{mainarea}", mainarea.toString());
        return {
            relative: replace("/{mainarea}/status", mainarea),
            absolute: replace(coreSite + "/{mainarea}/status", mainarea)
        };
    },
    "status_New": (mainarea: server.dto.MainAreas) => {
        const replace = (input: string, mainarea: server.dto.MainAreas) => input.replace("{mainarea}", mainarea.toString());
        return {
            relative: replace("/{mainarea}/status/new", mainarea),
            absolute: replace(coreSite + "/{mainarea}/status/new", mainarea)
        };
    },
    "subscription_Detail": () => ({ relative: "/subscription", absolute: coreSite + "/subscription"}),
    "subscription_PaymentSetupComplete": () => ({ relative: "/subscription/payment-setup-complete", absolute: coreSite + "/subscription/payment-setup-complete"}),
    "systemSetting_Config": () => ({ relative: "/systemsettings/config", absolute: coreSite + "/systemsettings/config"}),
    "systemSetting_Menu": () => ({ relative: "/systemsettings", absolute: coreSite + "/systemsettings"}),
    "systemWideFiles_List": () => ({ relative: "/file/systemwide", absolute: coreSite + "/file/systemwide"}),
    "tag_Edit": (mainarea: server.dto.MainAreas, key: number) => {
        const replace = (input: string, mainarea: server.dto.MainAreas, key: number) => input.replace("{mainarea}", mainarea.toString()).replace("{key}", key.toString());
        return {
            relative: replace("/{mainarea}/tag/{key}/edit", mainarea, key),
            absolute: replace(coreSite + "/{mainarea}/tag/{key}/edit", mainarea, key)
        };
    },
    "tag_List": (mainarea: server.dto.MainAreas) => {
        const replace = (input: string, mainarea: server.dto.MainAreas) => input.replace("{mainarea}", mainarea.toString());
        return {
            relative: replace("/{mainarea}/tag", mainarea),
            absolute: replace(coreSite + "/{mainarea}/tag", mainarea)
        };
    },
    "tag_New": (mainarea: server.dto.MainAreas) => {
        const replace = (input: string, mainarea: server.dto.MainAreas) => input.replace("{mainarea}", mainarea.toString());
        return {
            relative: replace("/{mainarea}/tag/new", mainarea),
            absolute: replace(coreSite + "/{mainarea}/tag/new", mainarea)
        };
    },
    "templateSetting_Menu": () => ({ relative: "/templatesettings", absolute: coreSite + "/templatesettings"}),
    "train_List": () => ({ relative: "/train", absolute: coreSite + "/train"}),
    "wordTemplate_Edit": (key: number) => {
        const replace = (input: string, key: number) => input.replace("{key}", key.toString());
        return {
            relative: replace("/wordtemplatev2/{key}/edit", key),
            absolute: replace(coreSite + "/wordtemplatev2/{key}/edit", key)
        };
    },
    "wordTemplate_List": (mainarea: server.dto.MainAreas) => {
        const replace = (input: string, mainarea: server.dto.MainAreas) => input.replace("{mainarea}", mainarea.toString());
        return {
            relative: replace("/{mainarea}/wordtemplate", mainarea),
            absolute: replace(coreSite + "/{mainarea}/wordtemplate", mainarea)
        };
    },
    "wordTemplate_New": (mainarea: server.dto.MainAreas) => {
        const replace = (input: string, mainarea: server.dto.MainAreas) => input.replace("{mainarea}", mainarea.toString());
        return {
            relative: replace("/{mainarea}/wordtemplatev2/new", mainarea),
            absolute: replace(coreSite + "/{mainarea}/wordtemplatev2/new", mainarea)
        };
    }
};